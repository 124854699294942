@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
}
.normal {
  transform: translateY(-100px);
}
.scroll {
  background-color: white;
  color: black;
  transition: all 0.5s ease-in;
}
#bg-img {
  background-image: url("../public/Images/bg8.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}
#bg-img::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 320px;
  background-color: rgba(0, 0, 0, 0.4);
}
#bg-img2 {
  background-image: url("../public/Images/bg6.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

#bg-img3 {
  clip-path: polygon(100% 0, 100% 68%, 51% 100%, 0 71%, 0 0);
  background-image: url("../public/Images/bg3.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}
#bg-img3::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 475px;
  background-color: rgba(0, 0, 0, 0.3);
}
#bg-img4 {
  background-image: url("../public/Images/bg15.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}
#bg-img4::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 320px;
  background-color: rgba(0, 0, 0, 0.5);
}
.scr .h1,
.scr .h2 {
  transition: all 0.5s ease-in-out;
}
.scr:hover .h1 {
  transform: translateY(-400px);
}
.scr:hover .h2 {
  transform: translateY(-350px);
}
.src2 .h1,
.src2 .h2 {
  transition: all 0.5s ease-in-out;
}
.src2:hover .h1 {
  transform: translateY(-40px);
}
.src2:hover .h2 {
  transform: translateY(-48px);
}
.slick-slide > div {
  margin: 0 10px;
}

.active {
  border-bottom: 2px solid #333;
  opacity: 80%;
  font-weight: 700;
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
  background-color: black;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 240px;
  text-align: center;
}

.container:hover .image {
  background-color: black;
  opacity: 0.2;
}

.container:hover .middle {
  opacity: 1;
}
